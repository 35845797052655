<template>
  <div
    class="IobCard level-container focus-inside elevation-on-hover level-1"
    tabindex="0"
  >
    <div class="IobCard-image">
      <iob-image
        :src="isLoading ? null : coverSrc"
        :render-type="coverRenderType"
      />
    </div>
    <div class="IobCard-bottom-section">
      <div class="IobCard-bottom-section-info">
        <div class="IobCard-bottom-section-info-subtitle">
          <iob-skeleton
            v-if="isLoading"
            width="50%"
          />
          <span v-else>{{ subTitle }}</span>
        </div>
        <div class="IobCard-bottom-section-info-title-with-status">
          <div
            class="IobCard-bottom-section-info-title-text"
            :class="loadingTitleClass"
          >
            <iob-skeleton
              v-if="isLoading"
              width="100%"
            />
            <span v-else>{{ title }}</span>
          </div>
          <iob-badge
            v-if="!isLoading && status"
            size="s"
            color="secondary"
            type="outlined"
            :label="status"
          />

          <iob-skeleton
            v-if="isLoading"
            width="25%"
            height="24px"
          />
        </div>
        <div
          v-if="badges.length"
          class="IobCard-bottom-section-info-tags"
        >
          <iob-skeleton
            v-for="(_, index) in badges"
            v-show="isLoading"
            :key="index"
            width="80px"
            height="24px"
          />
          <iob-badge
            v-for="badge in badges"
            v-show="!isLoading"
            :key="badge"
            size="s"
            :color="badge.color"
            :label="badge.label"
          />
        </div>
      </div>
      <div
        v-if="showActions && isLoading"
        class="IobCard-bottom-section-actions"
      >
        <iob-skeleton
          shape="icon"
          icon-name="Star"
          icon-size="large"
        />
        <iob-skeleton
          shape="icon"
          icon-name="MoreVertical"
          icon-size="large"
        />
      </div>
      <div
        v-if="showActions && !isLoading"
        class="IobCard-bottom-section-actions"
      >
        <iob-action-button
          color="secondary"
          size="large"
          type="ghost"
          icon-name="Star"
          @click="handleFavoriteActionButtonClick"
        />
        <iob-action-button
          color="secondary"
          size="large"
          type="ghost"
          icon-name="MoreVertical"
          @click="handleMoreActionButtonClick"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import IobImage from '../../../Atoms/IobImage/IobImage.vue';
import IobBadge from '../../../Atoms/IobBadge/IobBadge.vue';
import IobActionButton from '../../IobActionButton/IobActionButton.vue';
import IobSkeleton from '../../../Atoms/IobSkeleton/IobSkeleton.vue';
import {computed} from 'vue';

const props = defineProps({
  coverSrc: {
    type: String,
    default: ''
  },
  coverRenderType: {
    type: String,
    default: '16:9',
    validator: (value) => ['fit-to-box', 'fit-to-img', '1:1', '16:9', '4:3', 'golden'].includes(value)
  },
  title: {
    type: String,
    default: ''
  },
  subTitle: {
    type: String,
    default: ''
  },
  showActions: {
    type: Boolean,
    default: true
  },
  status: {
    type: String,
    default: ''
  },
  badges: {
    type: Array,
    default: () => [],
    validator: (value) => value.every((badge) => 'label' in badge && 'color' in badge)
  },
  isLoading: {
    type: Boolean,
    default: false
  }
});

const emits = defineEmits(['onClickFavoriteActionButton', 'onClickMoreActionButton']);
const handleFavoriteActionButtonClick = () => {
  emits('onClickFavoriteActionButton');
};

const handleMoreActionButtonClick = () => {
  emits('onClickMoreActionButton');
};

const loadingTitleClass = computed(() => ({
  'IobCard-bottom-section-info-title-text--is-loading': props.isLoading
}));

</script>

<style lang="scss" src="./IobCard.scss" scoped />
