const actions = {
  initEditor({ rootState, commit }, datasetElementId) {
    const datasetElement = rootState.board.datasetElements[datasetElementId];
    const dataType = rootState.app.dataTypes[datasetElement.typeId];
    commit('setDatasetElementId', datasetElementId);
    commit('setDataType', dataType);
  },
  resetEditor({ commit }) {
    commit('setDataType', null);
    commit('setDatasetElementId', null);
    commit('setHierarchicalCount', 0);
  },
  closeEditor({commit, rootState, dispatch}) {
    const { lastBoardElementIdInEdition } = rootState.board;
    if (lastBoardElementIdInEdition) {
      dispatch('board/notifyEditorClosed', lastBoardElementIdInEdition, {root: true});
    }
    commit('board/setLastBoardElementIdInEdition', null, {root: true});
    commit('setIsEditorOpen', false);
  },
  async openEditor({commit, dispatch, rootState}, {datasetElementId, boardElementId}) {
    const dataTypeEditorMapper = {
      'Indicator': 'indicator',
      'Embed': 'embed',
      'Note': 'note',
      'Card': 'card',
      'Image': 'image',
      'Bookmark': 'bookmark'
    };
    if (rootState.board.formulaInEditionBoardElementId) {
      dispatch('board/removeFormulaFromSelection', rootState.board.formulaInEditionBoardElementId, {root: true});
    }
    commit('board/setLastBoardElementIdInEdition', boardElementId ? boardElementId : rootState.board.lastBoardElementIdInEdition, {root: true});
    dispatch('initEditor', datasetElementId);
    const dataType = rootState.app.dataTypes[rootState.board.datasetElements[datasetElementId].typeId];
    if (dataType.name === 'List') {
      return;
    }
    const editorType = dataTypeEditorMapper[dataType.name] || 'card';
    commit('setEditorType', editorType);
    if (boardElementId) {
      dispatch('board/notifyEditorOpen', boardElementId, {root: true});
    }
    commit('setIsEditorOpen', true);
  },
  async addPreviousDatasetElement({ state, commit }, datasetElementId) {
    const previousDatasetElementIds = [...state.previousDatasetElementIds, datasetElementId];
    commit('setPreviousDatasetElementIds', previousDatasetElementIds);
  },
  async openPreviousDetailsEditor({ state, commit, dispatch }) {
    const previousDatasetElementId = state.previousDatasetElementIds[state.previousDatasetElementIds.length - 1];
    if (!previousDatasetElementId) {
      return ;
    }
    const newPreviousDatasetElementIds = state.previousDatasetElementIds.slice(0, -1);
    commit('setPreviousDatasetElementIds', newPreviousDatasetElementIds);
    dispatch('openEditor', {datasetElementId: previousDatasetElementId});
  }
};

export default actions;
