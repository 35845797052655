<template>
  <div class="IobTimeframe">
    <div @click="showDatepicker">
      <iob-label-field
        v-if="defaultField"
        class="IobTimeframe-icon"
        :model-value="getLabel"
        size="medium"
        :container-styles="inputStyle"
        type="ghost"
        badge-color="secondary"
        badge-icon="CalendarRange"
        :has-badge="true"
        :read-only="readOnly"
        :text="readOnly ? getLabel : ''"
        has-clear-button
        placeholder="Select a Date"
        @update:modelValue="(value) => handleLabelChange(value)"
      />
      <slot />
    </div>
    <div
      v-show="toggleTimeframe"
      class="IobTimeframe-datepickers level-container level-3"
      :style="datePickerStyles"
    >
      <div
        v-if="types.length > 1"
        class="IobTimeframe-datepickers-tabs"
      >
        <div
          v-for="(tab, index) in tabs"
          :key="index"
          class="IobTimeframe-datepickers-tabs--tab"
          @click.stop="handleActiveTab(tab.value)"
        >
          <div :class="tabsClasses(tab.value)">
            <h3>{{ tab.value }}</h3>
          </div>
        </div>
      </div>
      <div class="IobTimeframe-datepickers-content">
        <outside-click-listener
          v-if="toggleTimeframe"
          @outside-click="handleClickOutside"
        >
          <iob-date-range-picker
            v-if="isActiveTab === 'custom'"
            :start="attributes['start-date']"
            :end="attributes['end-date']"
            @on-date-change="
              ({ startDate, endDate }) => handleDateChange(startDate, endDate)
            "
          />
          <div
            v-if="isActiveTab === 'standard'"
            class="IobTimeframe-standard"
          >
            <div class="IobTimeframe-standard-buttonGroup">
              <iob-button
                :type="mode === 'M' ? 'ghost' : 'outlined'"
                color="secondary"
                size="medium"
                label="M"
                :selected="mode === 'M'"
                @click="setMode('M')"
              />
              <iob-button
                :type="mode === 'Q' ? 'ghost' : 'outlined'"
                color="secondary"
                size="medium"
                label="Q"
                :selected="mode === 'Q'"
                @click="setMode('Q')"
              />
              <iob-button
                :type="mode === 'S' ? 'ghost' : 'outlined'"
                color="secondary"
                size="medium"
                label="S"
                :selected="mode === 'S'"
                @click="setMode('S')"
              />
              <iob-button
                :type="mode === 'Y' ? 'ghost' : 'outlined'"
                color="secondary"
                size="medium"
                label="Y"
                :selected="mode === 'Y'"
                @click="setMode('Y')"
              />
            </div>
          </div>
          <iob-standard-picker
            v-if="isActiveTab === 'standard'"
            :mode="mode"
            :year="attributes['year']"
            :period="attributes['period']"
            @change-period="
              ({ year, period }) => handleDateChange(year, period)
            "
          />
        </outside-click-listener>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, reactive, watch } from 'vue';
import IobDateRangePicker from '../IobDateRangePicker/IobDateRangePicker.vue';
import IobLabelField from '../IobLabelField/IobLabelField.vue';
import IobButton from '../IobButton/IobButton.vue';
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';
import {
  formatDateToLabel
} from '../../../utils';
import IobStandardPicker from './IobStandardPicker/IobStandardPicker.vue';

const props = defineProps({
  defaultField: {
    type: Boolean,
    default: true,
  },
  types: {
    type: Array,
    default: () => [],
  },
  defaultType: {
    type: String,
    default: 'custom',
  },
  dateSettings: {
    type: Object,
    default: () => ({}),
  },
  componentType: {
    type: String,
    default: '',
  },
  readOnly: {
    type: Boolean,
    default: false
  },
  inputStyle: {
    type: String,
    default: ''
  },
  datePickerStyles: {
    type: String,
    default: ''
  },
  toggleTimeframe: {
    type: Boolean,
    default: false,
  },
});

const mode = ref(props.dateSettings.frame || 'M');
const tabs = ref(props.types ? props.types : []);
const isActiveTab = ref(props.dateSettings.type || props.defaultType);
const toggleTimeframe = ref(props.toggleTimeframe);
const attributes = reactive({});

const emit = defineEmits(['handleDateChange']);

/* Computed */
const tabsClasses = (index) => {
  return {
    'IobTimeframe-datepickers-tabs--title': true,
    'IobTimeframe-datepickers-tabs--title-active': isActiveTab.value === index,
  };
};

onMounted(() => {
  Object.keys(props.dateSettings).forEach((attr) => {
    attributes[attr] = props.dateSettings[attr];
  });
});

/* Computed */
const getLabel = computed(()=> formatDateToLabel(isActiveTab, attributes) )
/* Methods */

/* Tabular logic */
const handleActiveTab = (value) => {
  isActiveTab.value = value;
};

/* Range Datepicker Logic */
const showDatepicker = () => {
  toggleTimeframe.value = !toggleTimeframe.value;
};

const setMode = (value) => {
  mode.value = value;
};

const handleLabelChange = (value) => {
  if (value === '') {
    if (isActiveTab.value === 'custom') {
      attributes['start-date'] = '';
      attributes['end-date'] = '';
      let date = {
        'start-date': attributes['start-date'],
        'end-date': attributes['end-date'],
        type: isActiveTab.value,
      };
      if (props.componentType == 'dateRange') {
        date['is-due-date'] = true;
      }
      emit('handleDateChange', {date, label: getLabel.value });
    } else {
      attributes['year'] = new Date().getFullYear();
      attributes['period'] = '';
      attributes['frame'] = 'M';
      mode.value = 'M';
      let date = { type: isActiveTab.value, year: '', period: 1, frame: '' };
      if (props.componentType == 'dateRange') {
        date['is-due-date'] = true;
      }
      emit('handleDateChange', {});
    }
  }
};

const handleDateChange = (firstVal, secondVal) => {
  if (isActiveTab.value === 'custom') {
    const firstDate = new Date(firstVal);
    const secondDate = new Date(secondVal);

    if (firstDate > secondDate) {
      [firstVal, secondVal] = [secondVal, firstVal];
    }
    attributes['start-date'] = firstVal;
    attributes['end-date'] = secondVal;

    let date = {
      type: isActiveTab.value,
    };

    if (firstVal !== '') {
      date['start-date'] = firstVal;
    }
    date['end-date'] = secondVal;

    if (props.componentType === 'dateRange') {
      date['is-due-date'] = true;
    }

    emit('handleDateChange', {date, label: getLabel.value });
    attributes['year'] = new Date().getFullYear();
    attributes['period'] = '';
    attributes['frame'] = 'M';
    mode.value = 'M';
  } else {
    attributes['year'] = firstVal;
    attributes['period'] = secondVal;
    attributes['frame'] = mode.value;
    let date = {
      type: isActiveTab.value,
      year: firstVal,
      period: mode.value === 'Y' ? 1 : secondVal,
      frame: mode.value,
    };
    if (props.componentType == 'dateRange') {
      date['is-due-date'] = true;
    }
    emit('handleDateChange', { date, label: getLabel.value });
    //reset the custom
    attributes['start-date'] = '';
    attributes['end-date'] = '';
  }
};

const handleClickOutside = () => {
  toggleTimeframe.value = false;
};

watch(
  () => props.dateSettings,
  (newVal) => {
    Object.keys(newVal).forEach((attr) => {
      attributes[attr] = newVal[attr];
    });
  }
);
</script>

<style lang="scss" src="./IobTimeframe.scss" scoped />
