import { MUTATION_TYPES, INTERACTION_MODES } from 'GLOBALS/constants.js';
import { keyUpActions, keyDownActions } from 'BOARD/hot-keys-reducer';
import { ClickHandlersByTooltype } from './boardElementClickHandlers/clickHandlersByType';

const findMovableElementId = (path) => {
  const movableElement = path.find((el) => el.classList && el.classList.length > 0 && el.classList.contains('Movable'));
  return movableElement ? movableElement.id : null;
};

const handleClickedELement = (board, elementId) => {
  if (board.isEditorOpen && board.lastBoardElementIdInEdition !== elementId && elementId !== null) {
    board.deleteBoardElementInEdition({
      boardElementId: board.lastBoardElementIdInEdition
    });
    board.setClickedElementId(elementId);
  }
};
const handleElementClick = (board, event, elementId, toolType) => {
  const isElementSelected = board.userSelectedElements ? board.userSelectedElements.includes(elementId) : false;
  const isDelete = (event.ctrlKey || event.shiftKey || event.altKey) && isElementSelected;

  if (isDelete) {
    return board.removeSelection([elementId]);
  } else {
    const ClickHandlersByTooltypeInstance = new ClickHandlersByTooltype(toolType, elementId);
    ClickHandlersByTooltypeInstance.run({board, event});
  }
};

const eventsHandlers = {
  [INTERACTION_MODES.SELECT]: {
    click: (board, { event }) => {
      const path = event.path || (event.composedPath && event.composedPath());
      const elementId = findMovableElementId(path);
      handleClickedELement(board, elementId);
      if (!board.canSelect) {
        board.setCanSelect();
        return;
      }
      const viewTypeId = board.elements[elementId]?.viewTypeId;
      const toolType = board.viewTypes[viewTypeId]?.toolType;
      handleElementClick(board, event, elementId, toolType);
    },
    mouseleave: (board) => {
      board.setCursor({ value: 'default', lock: false });
    },
    dblclick: () => { },
    pointerdown: async (board, { event }) => {
      const path = event.path || (event.composedPath && event.composedPath());
      const elementId = findMovableElementId(path);
      if (event.which === 3) {
        board.send('rightMouseDown',  { board, event: {...event, elementId} });
        return;
      } else if (event.which === 2) {
        const eventExpression = board.getEventExpression(event);
        board.addHotKey(eventExpression);
        await keyDownActions[eventExpression](board);
      }
      board.send('pressOnBoard', { board, event });
    },
    pointermove: (board, { event }) => {
      const path = event.path || (event.composedPath && event.composedPath());
      const elementId = findMovableElementId(path);
      board.setCursor({ value: 'move', lock: false });
      const updatedEventObject = Object.assign(event, {elementId}, {});
      board.send('mouseDrag', { board, event: updatedEventObject});
      board.setIsSelectedVisualLink(false);
    },
    pointerup: (board, { event }) => {
      const path = event.path || (event.composedPath && event.composedPath());
      const elementId = findMovableElementId(path);
      const isLeftMouseDown = board.state.historyValue?.current === 'rightMouse' || event.which === 3;
      if (isLeftMouseDown || (board.isSelectionRectangle && board.selectedInteractionMode === INTERACTION_MODES.SELECT)) {
        board.send('mouseUp', { board, event: {...event, elementId} });
      }
    }
  },
  [INTERACTION_MODES.PANNING]: {
    click: (board, { event }) => {
      board.unlockCursor();
      board.setCursor({ value: 'grab', lock: false });
      const path = event.path || (event.composedPath && event.composedPath());
      const movableElement = path.find((el) => el.classList && el.classList.length > 0
        && Object.values(el.classList).includes('Movable'));
      const elementId = movableElement.id;
      if (!board.canSelect) {
        board.setCanSelect();
        return;
      }
      const isElementSelected = board.userSelectedElements.find((el) => el === elementId);
      const isDelete = (event.ctrlKey || event.shiftKey) && isElementSelected;
      if (isDelete) {
        return board.removeSelection([elementId]);
      }
      const mutationType = (event.shiftKey || event.ctrlKey) && !isElementSelected ?
        MUTATION_TYPES.APPEND_TO_PREVIOUS : MUTATION_TYPES.ERASE_PREVIOUS;
      board.selectBoardElements({ boardElementsIds: [elementId], mutationType });
    },
    mouseleave: (board) => {
      board.setCursor({ value: 'default', lock: false });
    },
    dblclick: () => { },
    pointerdown: (board, { event }) => {
      board.send('pressOnBoard', { board, event });
    },
    pointermove: (board, { event }) => {
      board.setCursor({ value: 'grab', lock: false });
      board.send('mouseDrag', { board, event });
      board.setIsSelectedVisualLink(false);
    },
    pointerup: async (board, { event }) => {
      if ([3, 2].includes(event.which) && board.isQuickPanning) {
        const eventExpression = board.getEventExpression(event);
        await board.removeHotKey(eventExpression);
        keyUpActions[eventExpression](board);
      }
      board.send('mouseUp', { board, event });
    }
  },
  [INTERACTION_MODES.LINKS]: {
    click: async (board, { event }) => {
      const path = event.path || (event.composedPath && event.composedPath());
      const boardElement = path.find((el) => el.classList && el.classList.length > 0
        && Object.values(el.classList).includes('Movable'));
      board.setLinkSourceCoordinates(event);
      await board.setLink(boardElement.id);
      board.processRelation();
    },
    mouseleave: (board) => {
      board.setCursor({ value: 'default', lock: false });
    },
    dblclick: () => { },
    pointerdown: () => {
    },
    pointermove: (board) => {
      board.setCursor({ value: 'crosshair', lock: false });
    },
    pointerup: async () => {
    }
  }
};
export default eventsHandlers;

