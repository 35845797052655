<template>
  <div
    v-if="collaborators.length <= maxDisplayedCollorators"
    :class="avatarGroupClass"
  >
    <iob-avatar
      v-for="(collaborator, index) in collaborators"
      :key="index"
      :picture="collaborator.image"
      :color-bottom="collaborator.colorBottom"
      :color-left="collaborator.colorLeft"
      :alt-text="collaborator.altText"
      :color="collaborator.color"
      :deleted-user="collaborator.deletedUser"
      :selected="collaborator.selected"
      :size="size"
    />
  </div>
  <div
    v-else
    :class="avatarGroupClass"
    @mouseleave="hiddenCollorators = false"
  >
    <iob-avatar
      v-for="(collaborator, index) in collaborators.slice(
        0,
        maxDisplayedCollorators
      )"
      :key="index"
      :picture="collaborator.image"
      :alt-text="collaborator.altText"
      :color-bottom="collaborator.colorBottom"
      :color-left="collaborator.colorLeft"
      :color="collaborator.color"
      :deleted-user="collaborator.deletedUser"
      :selected="collaborator.selected"
      :size="size"
    />
    <iob-hidden-avatar-list
      :users="parseUsers"
      :size="size"
    />
  </div>
</template>
    
<script setup>
import { defineProps, computed, ref } from 'vue';
import IobHiddenAvatarList from '../IobHiddenAvatarList/IobHiddenAvatarList.vue';
import IobAvatar from '../IobAvatar/IobAvatar.vue';

const props = defineProps({
  size: {
    type: String,
    default: 'large',
  },
  maxDisplayedCollorators: {
    type: Number,
    default: 2,
  },
  collaborators: {
    type: Array,
    default: () => [],
  }
});
      
const parseUsers = computed(() => {
  return props.collaborators.slice(props.maxDisplayedCollorators).map((collaborator) => {
    return {
      title: `${collaborator.firstname} ${collaborator.lastname}`,
      avatar: {
        picture: collaborator.image,
        altText: collaborator.altText,
        colorLeft: true,
        color: collaborator.color,
        size: collaborator.size,
      },
      type: 'member'
    }
  });
});

const hiddenCollorators = ref(false);
const avatarGroupClass = computed(() => {
  return `IobAvatarGroup IobAvatarGroup-${props.size}`
});

</script>
    
    <style lang="scss" src="./IobAvatarGroup.scss" scoped />
    