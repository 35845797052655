<template>
  <div>
    <iob-editor
      v-if="datasetElement"
      :id="datasetElementId"
      :dataset-element="datasetElement"
      :data-type-id="dataType.id"
      :data-type-name="dataType.name"
      :eligible-relations-datatypes="eligibleRelationsDatatypes"
      :selected-data-type-types="selectedDataTypeTypes"
      :friendly-id="friendlyId"
      :type="datasetElement.attributes.type"
      :owner="getOwner(datasetElement.attributes.owner)"
      :users="allUsers"
      :list-attributes="listAttributes"
      :main-panel-attributes="getMainPanelAttributes"
      :central-attributes-panel="getCentralPanelAttributes"
      :data-type-attributes="dataType.attributes"
      :selected-type="selectedType"
      :parent="getParent"
      :children="getChildren"
      :dependencies="getDependencies"
      :eligible-children="getEligibleChildren"
      :eligible-parents="getEligibleParents"
      :eligible-dependencies="getEligibleDependencies"
      :eligible-child-data-types="getEligibleChildDataTypes"
      :eligible-parent-data-types="getEligibleParentDataTypes"
      :relation-config-attributes="relationConfigAttributes"
      :has-summary="hasSummary"
      :show-attachment-dropdown="isAttachmentFeatureEnabled"
      :summary-config="getSummaryConfig"
      :levels="levelsTree"
      :previous-dataset-element="getPreviousDatasetElement"
      @closeEditor="closeEditorWrapper"
      @handleAttributeChange="
        ({ attribute, value }) => handleAttributesChange({ attribute, value })
      "
      @onClickTypeOption="(data) => fetchEligibleDatasetElements(data)"
      @onClickAddRelations="(data) => fetchEligibleDatasetElements(data)"
      @onClickCreateAsNewTypeName="(data) => createRelationWithNewDatasetElement(data)"
      @onClickAddRelationWithExistingDatasetElement="
        (data) => createRelationWithExistingDatasetElement(data)
      "
      @onClickDetailsMenu="executeAction"
      @search="(data) => handleSearch(data)"
      @onClickDetailsItem="openNewElementDetails"
      @onClickPreviousElement="goBackToPreviousElementDetails"
    />
    <div
      v-if="isDialogOpened"
      class="EditorWrapper-dialogBox"
    >
      <iob-dialog-box
        title="Your element won’t be saved"
        content="We won’t save your element if you don’t specify a title"
        submit-action="Discard"
        cancel-action="Go back"
        @submitAction="discardElement"
        @cancelAction="closeDialogBox"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import {FEATURES, RELATION_TYPES} from 'GLOBALS/constants.js';
import {isFeatureEnabled} from '@/utils/utils';
import { useDebounce, debounce } from 'SRC/utils/utils.js';

export default {
  name: 'BoardElementEditor',
  props: {
    elementId: {
      type: String,
      required: true
    }
  },
  emits: ['close', 'valueChanging', 'valueChange'],
  data() {
    return {
      selectedRelation: null,
      selectedType: null,
      selectedDataTypeName: '',
      isDialogOpened: false,
      currentSelectorInputValue: '',
      currentMultipleSelectvalue: '',
      previousTitle: '',
      debouncedSearch: null,
      previousDatasetElement: null,
      textAttributes: {},
      debounceAttributes: null,
      cancelFn: null
    };
  },
  computed: {
    ...mapState('editor', [
      'dataType',
      'datasetElementId',
      'hierarchicalCount',
      'filterId',
      'previousDatasetElementIds',
      'isEditorOpen'
    ]),
    ...mapState('app', ['dataTypes', 'levelsTree']),
    ...mapState('users', ['users', 'currentUser']),
    ...mapState('dock', ['isCreating']),
    ...mapState('board', [
      'datasetElements',
      'boardElementsInEdition',
      'dialogBoxFlags',
      'selectedDatasetElement',
      'filters',
      'notifications'
    ]),
    ...mapState('hierarchy', [
      'eligibleParentDataTypes',
      'eligibleChildDataTypes',
      'eligibleParents',
      'eligibleChildren',
      'eligibleDependencies',
      'parent',
      'children',
      'dependencies'
    ]),
    ...mapState({
      isAttachmentFeatureEnabled: (state) => isFeatureEnabled(state.app.featuresList, FEATURES.CARD_LINK_ATTACHMENT)
    }),
    ...mapGetters('app', ['getTitleAttribute', 'getDataTypeByName', 'getDataTypeName']),
    ...mapGetters('board', ['getDatasetElementById']),
    selectedDataTypeTypes() {
      const dataTypeName = this.selectedDataTypeName;
      const dataTypeAttributes = this.checkIfDatatypeHasTypeAttribute(dataTypeName);
      return dataTypeAttributes && dataTypeAttributes.enum && dataTypeName
        ? dataTypeAttributes.enum
        : [];
    },
    getPreviousDatasetElement() {
      const id = this.previousDatasetElementIds.length ? this.previousDatasetElementIds[this.previousDatasetElementIds.length - 1] : null;
      return id ? this.getDatasetElementById(id) : null;
    },
    relationConfigAttributes() {
      return this.dataType.editorConfig?.relationConfig?.attributes;
    },
    getParent() {
      return this.parent;
    },
    getChildren() {
      return this.children;
    },
    getDependencies() {
      return this.dependencies;
    },
    getEligibleChildren() {
      return this.eligibleChildren;
    },
    getEligibleParents() {
      return this.eligibleParents;
    },
    getEligibleDependencies() {
      return this.eligibleDependencies;
    },
    getEligibleChildDataTypes() {
      return this.eligibleChildDataTypes;
    },
    getEligibleParentDataTypes() {
      return this.eligibleParentDataTypes;
    },
    attributes() {
      return this.datasetElements[this.datasetElementId].attributes;
    },
    allUsers() {
      return this.users || [];
    },
    listAttributes() {
      if (this.dataType && this.dataType.attributes) {
        return this.dataType.attributes.map((e) =>
          this.dataType.attributes.find(({ name }) => e.name === name)
        );
      }
      return [];
    },
    getMainPanelAttributes() {
      return this.dataTypes[this.dataType.id]?.editorConfig?.general.mainAttributesPanel;
    },
    getCentralPanelAttributes() {
      return this.dataTypes[this.dataType.id]?.editorConfig?.general.centralAttributesPanel;
    },
    hasSummary() {
      return this.dataTypes[this.dataType.id]?.editorConfig?.general.hasSummary;
    },
    getSummaryConfig() {
      return this.dataTypes[this.dataType.id]?.editorConfig?.general.summaryConfig;
    },
    friendlyId() {
      return this.attributes['friendly-id'] || '';
    },
    datasetElement() {
      return this.getDatasetElementById(this.datasetElementId);
    },
    eligibleRelationsDatatypes() {
      return this.dataTypes[this.dataType.id].editorConfig.general
        .eligibleRelationsDatatypes;
    }
  },
  watch: {
    datasetElementId() {
      if (!this.datasetElements[this.datasetElementId]) {
        this.close();
      }
    }
  },
  created() {
    const { debounce: debounceAttributes, cancelFn } = useDebounce(() => {
      this.updateAttrs(this.textAttributes);
    }, 1000);
    this.debounceAttributes = debounceAttributes;
    this.cancelFn = cancelFn;
  },
  async mounted() {
    this.previousTitle = this.datasetElement.attributes?.title;
    await this.fetchEligibleDataTypes();
    await this.fetchHierarchicalRelations();
    await this.fetchDatasetElementDependencies();
    await this.fetchRelationTypes();
    await this.addFormulaResultToDatasetElement();

    this.debouncedSearch = debounce((searchValue) => {
      let attributes = {};
      if (this.selectedType) {
        attributes = { type: this.selectedType };
      }
      this.fetchEligibleDatasetElements({
        relation: this.selectedRelation,
        attributes,
        dataTypeName: this.selectedDataTypeName,
        searchValue
      });
    }, 500);
  },
  methods: {
    ...mapActions('board', ['updateDatasetElementFromAPI', 'updateDatasetElementAttributes', 'deleteSelectedDatasetElement', 'updateDatasetElements',
      'updateAttributeInRelatedDatasetElements', 'fetchDatasetElementById', 'fetchFilterResults', 'synchronizeIndicatorRelations'
    ]),
    ...mapActions('hierarchy', [
      'createDependencyWithExistingDatasetElement',
      'createRelationByTypeWithExistingElement',
      'fetchEligibleDataTypes',
      'fetchHierarchicalRelations',
      'fetchDatasetElementDependencies',
      'fetchEligibleElementsForRelations',
      'resetRelationsData',
      'createDependencyWithNewDatasetElement',
      'createRelationFromNewElement',
      'deleteRelations',
      'unlinkRelationalElement'
    ]),
    ...mapActions('editor', ['closeEditor', 'openEditor', 'addPreviousDatasetElement',
      'openPreviousDetailsEditor']),
    ...mapMutations('editor', ['setPreviousDatasetElementIds', 'setFilterId']),
    ...mapMutations('board', ['setDialogBoxFlags', 'setBoardElementsInEdition', 'addDatasetElement', 'setNotificationsInfo']),
    ...mapMutations('dock', ['setIsCreating']),
    ...mapActions('app', [
      'addFormulaResultToDatasetElement',
      'addFormulasResultsToElements',
      'updateAttributeInParent',
      'fetchRelationTypes'
    ]),
    closeDialogBox() {
      this.isDialogOpened = false;
    },
    async discardElement() {
      const isDeleted = await this.deleteSelectedDatasetElement(this.datasetElementId);
      await this.fetchFilterResults({id: this.filterId});
      if (!isDeleted) {
        return;
      }
      this.closeDialogBox();
      this.resetEditorWrapperData();
    },
    async closeEditorWrapper() {
      const title = this.textAttributes?.title;
      let attributes = {
        ...this.datasetElement.attributes,
        ...this.textAttributes,
        title
      };
      if (!title?.length) {
        if (this.isCreating) {
          this.isDialogOpened = true;
          return;
        } else {
          attributes = {
            ...this.datasetElement.attributes,
            title: this.previousTitle
          };
          this.updateAttributeInParent(this.datasetElementId, attributes);
          this.updateAttributeInRelatedDatasetElements(
            this.datasetElementId,
            attributes
          );
          this.cancelFn();
        }
      }
      if (this.filterId) {
        await this.updateDatasetElementFromAPI({datasetElementId: this.datasetElementId, attributes});
      } else {
        this.updateDatasetElementAttributes({
          datasetElementId: this.datasetElementId,
          attributes
        });
      }
      this.resetEditorWrapperData();
      this.synchronizeIndicatorRelations({kpiId: this.datasetElementId});
      await this.fetchFilterResults({id: this.filterId});
      if (this.filterId) {
        const isDatasetElementAdded = this.filters[this.filterId].find(
          (element) => element.id === this.datasetElementId
        );
        if (!isDatasetElementAdded && this.isCreating) {
          this.selectedDataTypeName = this.getDataTypeName(this.dataType.id, this.datasetElements[this.datasetElementId]);
          this.setNotificationsInfo({
            addElementToListNotification: {
              ...this.notifications.addElementToListNotification,
              isNotificationDisplayed: true,
              title: `${this.selectedDataTypeName} created but not visible`,
              message: 'For your new task to be visible, it must match your list filters.',
              action: 'openElement',
              callbackfn: async () => {
                this.setNotificationsInfo({
                  ...this.notifications,
                  ...this.setNotificationsInfo.addElementToListNotification,
                  isNotificationDisplayed: false
                });
                this.openEditor({datasetElementId: this.datasetElementId, boardElementId: this.elementId});
              }
            }
          });
        }
      }
      if (!this.notifications.addElementToListNotification.isNotificationDisplayed && !this.isCreating) {
        this.setFilterId(null);
      }
      this.setPreviousDatasetElementIds([]);
      this.setIsCreating(false);
      this.close();
      await this.synchronizeIndicatorRelations({kpiId: this.datasetElementId});
    },
    checkIfDatatypeHasTypeAttribute(dataTypeName) {
      const datatype = this.getDataTypeByName(dataTypeName);
      return (
        datatype && datatype.attributes.find((attribute) => attribute.name === 'type')
      );
    },
    async fetchEligibleDatasetElements({
      relation,
      attributes = {},
      dataTypeName,
      searchValue = ''
    }) {
      this.selectedType = null;
      this.selectedDataTypeName = dataTypeName;
      this.selectedRelation = relation;
      const typeAttribute = this.checkIfDatatypeHasTypeAttribute(dataTypeName);
      if (typeAttribute?.enum?.length) {
        this.selectedType = typeAttribute.enum[0].value;
      }

      if (!attributes.type && this.selectedType) {
        attributes.type = this.selectedType;
      }

      if (attributes.type) {
        this.selectedType = attributes.type;
      }

      const datatype = this.getDataTypeByName(dataTypeName);
      const searchQuery = searchValue ? `&search=${searchValue}` : '';
      const isDependency = relation === RELATION_TYPES[2];
      await this.fetchEligibleElementsForRelations(
        { type: relation,
          attributes,
          searchQuery,
          ...(isDependency && { dataTypeFilters: {[datatype.id]: {}} })
        }
      );
    },
    resetEditorWrapperData() {
      this.resetRelationsData();
      this.closeEditor();
    },
    getOwner(ownerId) {
      const owner = this.users.find((user) => user.id === ownerId);
      return owner ? owner : this.currentUser;
    },
    isObject(obj) {
      return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
    },
    updateAttrs(attributes) {
      this.updateDatasetElementAttributes({
        datasetElementId: this.datasetElementId,
        attributes
      });
      this.updateAttributeInParent(
        this.datasetElementId,
        attributes
      );
      this.updateAttributeInRelatedDatasetElements(
        this.datasetElementId,
        attributes
      );
      this.addFormulaResultToDatasetElement();
    },
    onTextInput() {
      this.debounceAttributes(this.textAttributes);
    },
    async handleAttributesChange({ attribute, value }) {
      const foundAttribute = this.listAttributes.find((attr) => attr.name === attribute);
      const { type } = foundAttribute;
      const previousAttrValue = this.datasetElement.attributes[attribute];
      const isEmptyIntAttrValue = type === 'int' && value.length === 0;
      const isTimestampedValue = type === 'timestamped_indicator';
      if (!this.isObject(value) && (previousAttrValue === value || isEmptyIntAttrValue)) {
        return;
      }
      let updatedValue = value;
      if (type === 'level') {
        updatedValue = value.id;
      } else if (isTimestampedValue) {
        updatedValue = {
          value: Number(value),
          timestamp: String(new Date().toISOString()).slice(0, -5)
        };
      }
      const attributes = {
        ...this.datasetElement.attributes,
        [attribute]: updatedValue
      };
      this.textAttributes = attributes;
      if (foundAttribute.type === 'string' && !foundAttribute.enum) {
        this.onTextInput();
        return;
      }

      this.updateAttributeInParent(this.datasetElementId, attributes);
      this.updateAttributeInRelatedDatasetElements(this.datasetElementId, attributes);
      this.updateDatasetElementAttributes({
        datasetElementId: this.datasetElementId,
        attributes
      });
      this.$emit('valueChanging');
      this.$emit('valueChange');
      await this.addFormulaResultToDatasetElement();
    },
    close() {
      if (
        this.currentSelectorInputValue.length ||
        this.currentMultipleSelectvalue.length
      ) {
        this.setDialogBoxFlags({
          ...this.dialogBoxFlags,
          isCancelElementRelationsEditing: true
        });
        return;
      }
      this.$emit('close');
    },
    escKeyAction(e) {
      if (e.key === 'Escape') {
        this.close();
      }
    },
    getDatasetElement(datasetElement) {
      this.currentSelectorInputValue = datasetElement ? datasetElement.inputText : '';
    },
    getChildrenToLink(childrenToLink) {
      this.currentMultipleSelectvalue = childrenToLink;
    },
    handleSearch(searchValue) {
      this.debouncedSearch(searchValue);
    },
    refreshDatasetElement(newDatasetElement) {
      if (newDatasetElement) {
        this.updateDatasetElements(newDatasetElement);
      }
    },
    async createRelationWithNewDatasetElement({ relation, body }) {
      const dataTypeId = this.getDataTypeByName(body.dataTypeName).id;
      delete body.dataTypeName;
      body = {
        attributes: {
          type: this.selectedType,
          title: body.title,
          owner: this.currentUser.id
        },
        dataTypeId
      };
      let newDatasetElement;
      if (relation === RELATION_TYPES[0]) {
        return;
      }
      if (relation === RELATION_TYPES[2]) {
        newDatasetElement = await this.createDependencyWithNewDatasetElement(body);
        this.addDatasetElement({datasetElementId: newDatasetElement.id, datasetElement: newDatasetElement});
        return;
      }
      newDatasetElement = await this.createRelationFromNewElement(body);
      this.addDatasetElement({datasetElementId: newDatasetElement.id, datasetElement: newDatasetElement});
    },
    async createRelationWithExistingDatasetElement({
      relation,
      eligibleDatasetElementId
    }) {
      if (relation === RELATION_TYPES[2]) {
        await this.createDependencyWithExistingDatasetElement({
          eligibleDatasetElementId
        });
        return;
      }
      await this.createRelationByTypeWithExistingElement({
        relation,
        eligibleDatasetElementId
      });
    },
    async executeAction({ action, relatedDatasetElementId, relationId, relationType }) {
      if (action === 'deleteAction') {
        await this.deleteRelations({ relationId, relatedDatasetElementId, relationType });
        this.addFormulaResultToDatasetElement();
        return;
      }
      if (action === 'unlinkAction') {
        await this.unlinkRelationalElement({ relationId, relatedDatasetElementId, relationType });
        this.addFormulaResultToDatasetElement();
      }
    },
    openNewElementDetails({id}) {
      this.addPreviousDatasetElement(this.datasetElementId);
      this.resetEditorWrapperData();
      setTimeout(() => this.openEditor({datasetElementId: id, boardElementId: this.elementId}));
    },
    goBackToPreviousElementDetails() {
      this.resetEditorWrapperData();
      setTimeout(() => this.openPreviousDetailsEditor());
    }
  }
};
</script>

<style scoped src="./BoardElementEditor.css" />
