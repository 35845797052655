<template>
  <div
    class="IobHiddenAvatarList"
    @click="handleClick"
  >
    <iob-avatar
      :size="size"
      :alt-text="usersNumber"
    />
    <iob-dropdown
      v-if="displayAllUsers"
      class="IobHiddenAvatarList--dropdown"
      :items="parseUsersWithTitle"
      :is-scrollable="true"
    />
  </div>
</template>

<script setup>
import IobAvatar from '../IobAvatar/IobAvatar.vue';
import IobDropdown from '../IobDropdown/IobDropdown.vue';

import { defineProps, ref, computed } from 'vue';

const props = defineProps({
  users: {
    type: [String, Array],
    required: true
  },
  size: {
    type: String,
    default: 'large',
  },
  altText: {
    type: String,
    default: '',
  },
});

const parseUsersWithTitle = computed(() => {
  return [
    { title: `${usersNumber.value} members on this board`, type: 'title' },
    ...props.users
  ];
});

const usersNumber = computed(() => `+${props.users.length}`);
const displayAllUsers = ref(false);
const handleClick = () => {
  displayAllUsers.value = !displayAllUsers.value;
};
</script>

<style lang="scss" src="./IobHiddenAvatarList.scss" scoped />
