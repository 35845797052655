<template>
  <div
    class="IobInputDropdown"
  >
    <iob-label-field
      placeholder=""
      :show-caracters-counter="false"
      :size="inputSize"
      :read-only="true"
      :has-default-style="false"
      :class="classList"
      @click="toggleDropdownClick"
    >
      <iob-badge
        v-if="type === 'badge' && status"
        class="IobInputDropdown--badge"
        :color="getBadgeColor"
        :size="badgeSize"
        :label="currentItem"
      />
      <span
        v-else
        class="IobInputDropdown--text"
      >{{ currentItem }}</span>
    </iob-label-field>
    <outside-click-listener
      v-if="toggleDropdown"
      @outside-click="handleClickOutsideDropdown"
    >
      <iob-dropdown
        v-if="toggleDropdown && items.length"
        :items="itemsDropdown"
        :class="{
          'IobInputDropdown--dropdown': true,
          'IobInputDropdown--dropdown-scrollable':isScrollable
        }"
        :is-scrollable="isScrollable"
        :dropdown-style="dropdownStyle"
        @dropdown-element-item="handleDropdownItemClick"
      />
    </outside-click-listener>
    <div />
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, watch} from 'vue';
import { defaultBadgeColors } from '../../../constants';
import IobDropdown from '../../Molecules/IobDropdown/IobDropdown.vue';
import IobLabelField from '../../Molecules/IobLabelField/IobLabelField.vue';
import IobBadge from '../../Atoms/IobBadge/IobBadge.vue';
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';

const props = defineProps({
  status: {
    type: String,
    default: ''
  },
  badgeSize: {
    type: String,
    default: 'xs'
  },
  type: {
    type: String,
    default: 'text'
  },
  inputSize: {
    type: String,
    default: 'default'
  },
  items: {
    type: Array,
    default: ()=>[]
  },
  dropdownStyle: {
    type: String,
    default: '',
  },
  isScrollable: {
    type: Boolean,
    default:true,
  },
});
const emits = defineEmits(['dropdownElementClick']);
const currentItem = ref(props.status);
const status = ref(props.status);

const toggleDropdown = ref(false);
const getBadgeColor = computed(() => {
  return defaultBadgeColors[currentItem.value];
});

const classList = computed(() => {
  return {
    'IobInputDropdown--input': true,
    'IobInputDropdown--focus': toggleDropdown.value
  }
});

const itemsDropdown = computed(() => {
  return props.items.map(item => {
    const isObject = typeof item === 'object';
    const text = isObject ? item.text : item;
    const id = isObject ? item.id : undefined;

    return {
      text: text,
      id: id,
      state: 'default',
      iconName: text === status.value ? 'Check' : '',
      iconSize: 'default',
      type: props.type === 'badge' ? 'badge' : 'menu',
      size: 'xs',
      color: getBadgeColor.value
    }
  });
});

watch(() => props.status, (value) => {
  currentItem.value = value;
  status.value = value;
});

const handleDropdownItemClick = ({item}) => {
  emits('dropdownElementClick', item);
  currentItem.value = item.text;
  status.value = item.text;
  toggleDropdown.value = false;
};

const toggleDropdownClick = ()=> {
  toggleDropdown.value = !toggleDropdown.value;
};

const handleClickOutsideDropdown = () => {
  toggleDropdown.value = false;
};
</script>

<style lang="scss" src="./IobInputDropdown.scss" scoped />
