<template>
  <div
    ref="widget"
    data-target="board-element"
    class="ListWidget level-container level-2"
    :style="{padding: 43 * (props.scale || 1) + 'px'}"
  >
    <div
      v-show="isHighlighted"
      data-target="board-element"
      class="ListWidget-highlighted"
    />
    <div
      data-target="board-element"
      class="ListWidget-indicator"
      :style="rightIndicatorWidth"
    />
    <div
      data-target="board-element"
      class="ListWidget-body"
      :style="containerStyle"
    >
      <div
        data-target="board-element"
        class="ListWidget-body-header"
      >
        <div
          data-target="board-element"
          class="ListWidget-body-header-info"
        >
          <icon-loader
            data-target="board-element"
            name="List"
            size="xl"
          />
          <span
            data-target="board-element"
            class="ListWidget-body-header-info-title"
          >
            {{ title }}
          </span>
          <span
            data-target="board-element"
            class="ListWidget-body-header-info-subtext"
          >
            •
          </span>
          <span
            data-target="board-element"
            class="ListWidget-body-header-info-subtext"
          >
            {{ list.length }}
          </span>
        </div>
        <iob-button
          data-target="board-element"
          type="ghost"
          color="primary"
          left-icon="Plus"
          :show-left-icon="true"
          label="New"
          size="large"
          button-style="height:75px; font-size: 32px; padding: 0 20px;"
          custom-button-text="display: block"
          left-icon-size="2xl"
          @click="addNewDatasetElement"
        />
      </div>
      <div
        v-if="!isResizing"
        data-target="board-element"
        class="ListWidget-body-content"
      >
        <iob-list-item
          v-for="(item, index) in paginatedArray"
          :key="index"
          data-target="board-element"
          :data-list-item-id="item.id"
          :left-items="item.leftItems"
          :right-items="item.rightItems"
          :hide-right-section="hideRightSection"
          size="medium"
          :style="`width: ${listItemWidth}`"
          :has-details="false"
          icon-size="default"
          @click="handleListItemClick"
        />
      </div>
      <div
        v-else
        data-target="board-element"
        class="ListWidget-body-content"
      >
        <ghost-list-line
          v-for="(item, index) in paginatedArray"
          :key="index"
          :style="`height: 88px; width: ${listItemWidth}; display: flex`"
        />
      </div>
    </div>
    <div
      v-if="numberOfPages > 1"
      data-target="board-element"
      class="ListWidget-footer"
      :style="{...containerStyle, bottom: `${38.8 * props.scale}px`, width: listItemWidth}"
    >
      <span
        data-target="board-element"
        class="ListWidget-footer-results"
      >
        {{ currentPageText }}
      </span>
      <div
        data-target="board-element"
        class="ListWidget-footer-pages"
      >
        <iob-action-button
          data-target="board-element"
          type="ghost"
          color="secondary"
          icon-name="ChevronsLeft"
          :disabled="currentPage === 1"
          @click="first"
        />
        <iob-action-button
          data-target="board-element"
          type="ghost"
          color="secondary"
          icon-name="ChevronLeft"
          :disabled="currentPage === 1"
          @click="previous"
        />
        <button
          v-for="index in start > end ? [] : range(start, end)"
          :key="index"
          data-target="board-element"
          class="ListWidget-footer-pages-button"
          :class="{'ListWidget-footer-pages-button--selected': currentPage === index}"
          :disabled="numberOfPages === 0"
          @click="goToPage(index)"
        >
          {{ index }}
        </button>
        <span
          v-if="hasReachedEnd"

          data-target="board-element"
        >...</span>
        <button
          data-target="board-element"

          class="ListWidget-footer-pages-button"
          :class="{'ListWidget-footer-pages-button--selected': currentPage === numberOfPages}"
          :disabled="numberOfPages <= 1"
          @click="goToPage(numberOfPages)"
        >
          {{ numberOfPages }}
        </button>
        <iob-action-button
          data-target="board-element"

          type="ghost"
          color="secondary"
          icon-name="ChevronRight"
          :disabled="currentPage >= numberOfPages"
          @click="next"
        />
        <iob-action-button
          data-target="board-element"
          type="ghost"
          color="secondary"
          icon-name="ChevronsRight"
          :disabled="currentPage >= numberOfPages"
          @click="last"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, watch, ref } from 'vue';
import { usePagination } from '../../../../hooks/usePagination';
import { range } from 'lodash';
import GhostListLine from './ghostListLine/GhostListLine.vue';
import { BOARDGET_LIST_BREAKPOINT } from '../../../../globals/constants';
import { useStore } from 'vuex';

const props = defineProps({
  list: {
    type: Array,
    required: true
  },
  filterId: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  scale: {
    type: Number,
    default: 1
  },
  rowsPerPage: {
    type: Number,
    default: 5
  },
  width: {
    type: Number,
    default: 1
  },
  height: {
    type: Number,
    default: 1
  },
  canBeHighlighted: {
    type: Boolean,
    default: true
  },
  elementId: {
    type: String,
    required: true
  },
  isResizing: {
    type: Boolean,
    default: false
  }
});

const store = useStore();

const displayedField = computed(() => props.list.map((item) => {
  const leftItems = [
    {
      fieldType: 'label',
      value: {
        text: item.type.text,
        color: item.type.color,
        icon: item.type.iconName
      }
    },
    {
      fieldType: 'title',
      value: item.friendlyId
    },
    {
      fieldType: 'text',
      value: item.title
    }
  ];
  let rightItems = [];
  if (item.status) {
    rightItems = [{
      fieldType: 'status',
      value: item.status
    },
    {
      fieldType: 'timeframe',
      value: item.timeframe
    },
    {
      fieldType: 'user',
      value: item.user
    }
    ];
  } else {
    rightItems = [{
      fieldType: 'title',
      value: item.initialValue ? item.initialValue + item.unit : ''
    }, {
      fieldType: 'title',
      value: item.targetValue ? item.targetValue + item.unit : ''
    },
    {
      fieldType: 'title',
      value: item.bestInClass
    }];
  }
  return {
    leftItems,
    rightItems,
    id: item.id
  };
}));
const {
  paginatedArray,
  first,
  last,
  next,
  previous,
  currentPage,
  numberOfPages,
  goToPage
} = usePagination(displayedField, {
  rowsPerPage: props.rowsPerPage
});

const start = computed(() => {
  if (numberOfPages.value <= 5) {
    return 1;
  }

  if (currentPage.value <= 3) {
    return 1;
  }

  if (currentPage.value >= numberOfPages.value - 3) {
    return numberOfPages.value - 5;
  }

  return currentPage.value - 2;
});
const end = computed(() => {
  if (numberOfPages.value <= 5) {
    return numberOfPages.value;
  }

  if (currentPage.value <= 3) {
    return 5;
  }

  if (currentPage.value >= numberOfPages.value - 3) {
    return numberOfPages.value;
  }

  return currentPage.value + 2;
});
const rightIndicatorWidth = computed(() => ({width: `${8 * props.scale}px`}));

/**43 is the padding and 30 is a fixed offset */
const boardgetListPadding = 43;
const boardgetListOffset = 30;
const listItemWidth = computed(() => ((props.width - (boardgetListPadding * (props.scale || 1))) / props.scale) - boardgetListOffset);

const hideRightSection = ref((props.width) < (BOARDGET_LIST_BREAKPOINT * props.scale));
watch(() => [props.isResizing], ([value]) => {
  if (!value) {
    return;
  }
  if ((props.width) < (BOARDGET_LIST_BREAKPOINT * props.scale)) {
    hideRightSection.value = true;
    return;
  }
  hideRightSection.value = false;
}, {immediate: true});

const currentPageText = computed(() => {
  const start = numberOfPages.value > 0 ? (currentPage.value - 1) * props.rowsPerPage + 1 : 0;
  const end = Math.min(currentPage.value * props.rowsPerPage, props.list.length);
  return `${start} - ${end} of ${props.list.length}`;
});

const hasReachedEnd = computed(() => numberOfPages.value > 5 && currentPage.value < numberOfPages.value - 3);

const isHighlighted = computed(() => props.canBeHighlighted);

const containerStyle = computed(() => ({
  transform: `scale(${props.scale})`,
  transformOrigin: 'top left',
  width: 'max-content',
  maxHeight: `${90 / props.scale}%`,
  overFlowY: 'clip'
}));
const handleListItemClick = () => {
  store.commit('editor/setFilterId', props.filterId);
};

const addNewDatasetElement = async () => {
  const elementsFilter =  await store.dispatch('board/getFilterDataType', {filterId: props.filterId});
  const type = elementsFilter.attributeSearches?.type?.[0] ?? null;

  const requestBody = {
    typeId: elementsFilter.dataTypeId,
    attributes: {
      owner: store.state.users.currentUser.id,
      type
    }
  };
  store.dispatch('board/createDatasetElement', {requestBody, filterId: props.filterId});
};

</script>

<style lang="scss" src="./ListWidget.scss" scoped />

