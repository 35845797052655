<template>
  <div>
    <iob-editor
      v-if="datasetElement"
      :id="datasetElementId"
      :dataset-element="datasetElement"
      :data-type-id="dataType.id"
      :data-type-name="dataType.name"
      :eligible-relations-datatypes="eligibleRelationsDatatypes"
      :selected-data-type-types="selectedDataTypeTypes"
      :friendly-id="friendlyId"
      :type="datasetElement.attributes.type"
      :owner="getOwner(datasetElement.attributes.owner)"
      :users="allUsers"
      :list-attributes="listAttributes"
      :main-panel-attributes="getMainPanelAttributes"
      :central-attributes-panel="getCentralPanelAttributes"
      :data-type-attributes="dataType.attributes"
      :selected-type="selectedType"
      :parent="getParent"
      :children="getChildren"
      :dependencies="getDependencies"
      :eligible-children="getEligibleChildren"
      :eligible-parents="getEligibleParents"
      :eligible-dependencies="getEligibleDependencies"
      :eligible-child-data-types="getEligibleChildDataTypes"
      :eligible-parent-data-types="getEligibleParentDataTypes"
      :relation-config-attributes="relationConfigAttributes"
      :has-summary="hasSummary"
      :invalid-attachment-link="invalidAttachmentLink"
      :show-attachment-dropdown="showAttachmentDropdown"
      :hidden-attributes="hiddenAttributes"
      :attachments-dropdown="attachmentsDropdown"
      :summary-config="getSummaryConfig"
      :levels="levelsTree"
      :can-use-time-series-data="canUseTimeSeriesData"
      :hidden-sections="hiddenSections"
      :hidden-tabs="hiddenTabs"
      :previous-dataset-element="getPreviousDatasetElement"
      :additional-data="additionalData"
      @closeEditor="closeEditorWrapper"
      @handleAttributeChange="
        ({ attribute, value }) => handleAttributesChange({ attribute, value })
      "
      @onClickTypeOption="(data) => fetchEligibleDatasetElements(data)"
      @onClickAddRelations="(data) => fetchEligibleDatasetElements(data)"
      @onClickCreateAsNewTypeName="(data) => createRelationWithNewDatasetElement(data)"
      @onClickAddRelationWithExistingDatasetElement="
        (data) => createRelationWithExistingDatasetElement(data)
      "
      @onClickDetailsMenu="executeAction"
      @search="(data) => handleSearch(data)"
      @onClickDetailsItem="openNewElementDetails"
      @onClickPreviousElement="goBackToPreviousElementDetails"
      @onClick:dropdownElement="(element) => addAttachment(element)"
      @onClick:action="(params) => handleAction(params)"
      @onClick:veil="(params) => handleAction(params)"
      @onChange:fieldValue="(value) => updateAttachmentUrlInput(value)"
    />
    <div
      v-if="isDialogOpened"
      class="EditorWrapper-dialogBox"
    >
      <iob-dialog-box
        title="Your element won’t be saved"
        content="We won’t save your element if you don’t specify a title"
        submit-action="Discard"
        cancel-action="Go back"
        @submitAction="discardElement"
        @cancelAction="closeDialogBox"
      />
    </div>
    <div
      v-if="openDeleteAttachmentDialog"
      class="EditorWrapper-dialogBox"
    >
      <iob-dialog-box
        id="deleteAttachmentDialog"
        :title="$t('attachment.error.popup.title', { attachmentName: attachmentToDelete.title })"
        :content="$t('attachment.error.popup.description')"
        :submit-action="$t('board.delete.button')"
        :cancel-action="$t('btn-cancel')"
        @submitAction="handleDeleteAttachment"
        @cancelAction="openDeleteAttachmentDialog=false;"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import {FEATURES, RELATION_TYPES, EDITOR_HIDDEN_FEATURES, MAX_ATTACHMENTS} from 'GLOBALS/constants.js';
import { debounce, isFeatureEnabled, isValidUrl, useDebounce } from 'SRC/utils/utils.js';
import { compareProxies } from 'BOARD/utils/utils';
import attachmentActions from './utils/fetchAttachments';

export default {
  name: 'BoardElementEditor',
  props: {
    elementId: {
      type: String,
      required: true
    }
  },
  emits: ['close', 'valueChanging', 'valueChange'],
  data() {
    return {
      selectedRelation: null,
      selectedType: null,
      selectedDataTypeName: '',
      isDialogOpened: false,
      currentSelectorInputValue: '',
      currentMultipleSelectvalue: '',
      previousTitle: '',
      debouncedSearch: null,
      previousDatasetElement: null,
      textAttributes: {},
      debounceAttributes: null,
      cancelFn: null,
      invalidAttachmentLink: false,
      attachmentUrlFieldValue: '',
      attachmentUrlName: '',
      additionalData: {},
      openDeleteAttachmentDialog: false,
      attachmentToDelete: null
    };
  },
  computed: {
    ...mapState('editor', [
      'dataType',
      'datasetElementId',
      'hierarchicalCount',
      'filterId',
      'previousDatasetElementIds',
      'isEditorOpen'
    ]),
    ...mapState('app', ['dataTypes', 'levelsTree', 'featuresList']),
    ...mapState('users', ['users', 'currentUser']),
    ...mapState('dock', ['isCreating']),
    ...mapState('board', [
      'datasetElements',
      'boardElementsInEdition',
      'dialogBoxFlags',
      'selectedDatasetElement',
      'filters',
      'elements'
    ]),
    ...mapState('hierarchy', [
      'eligibleParentDataTypes',
      'eligibleChildDataTypes',
      'eligibleParents',
      'eligibleChildren',
      'eligibleDependencies',
      'parent',
      'children',
      'dependencies'
    ]),
    ...mapState({
      isAttachmentFeatureEnabled: (state) => isFeatureEnabled(state.app.featuresList, FEATURES.CARD_LINK_ATTACHMENT),
      canUseTimeSeriesData: (state) => isFeatureEnabled(state.app.featuresList, FEATURES.KPI_HISTORIZATION_FEATURE)
    }),
    hiddenSections() {
      return Object.entries(EDITOR_HIDDEN_FEATURES.sections)
        .filter(([key]) => !isFeatureEnabled(this.featuresList, key))
        .flatMap(([, values]) => values);
    },
    hiddenTabs() {
      return Object.entries(EDITOR_HIDDEN_FEATURES.tabs)
        .filter(([key]) => !isFeatureEnabled(this.featuresList, key))
        .flatMap(([, values]) => values);
    },
    ...mapGetters('app', ['getTitleAttribute', 'getDataTypeByName', 'getDataTypeName']),
    ...mapGetters('board', ['getDatasetElementById']),
    selectedDataTypeTypes() {
      const dataTypeName = this.selectedDataTypeName;
      const dataTypeAttributes = this.checkIfDatatypeHasTypeAttribute(dataTypeName);
      return dataTypeAttributes && dataTypeAttributes.enum && dataTypeName
        ? dataTypeAttributes.enum
        : [];
    },
    showAttachmentDropdown() {
      return this.isAttachmentFeatureEnabled &&
      (this.datasetElement.attributes?.attachments ?? []).length < MAX_ATTACHMENTS;
    },
    getPreviousDatasetElement() {
      const id = this.previousDatasetElementIds.length ? this.previousDatasetElementIds[this.previousDatasetElementIds.length - 1] : null;
      return id ? this.getDatasetElementById(id) : null;
    },
    relationConfigAttributes() {
      return this.dataType.editorConfig?.relationConfig?.attributes;
    },
    getParent() {
      return this.parent;
    },
    getChildren() {
      return this.children;
    },
    getDependencies() {
      const dependencies = { ...this.dependencies };
      const eligibleRelationsDatatypes = this.eligibleRelationsDatatypes.map((dataType) => dataType.name);

      Object.keys(dependencies).forEach((name) => {
        if (!eligibleRelationsDatatypes.includes(name)) {
          delete dependencies[name];
        }
      });
      return dependencies;
    },
    getEligibleChildren() {
      return this.eligibleChildren;
    },
    getEligibleParents() {
      return this.eligibleParents;
    },
    getEligibleDependencies() {
      return this.eligibleDependencies;
    },
    getEligibleChildDataTypes() {
      return this.eligibleChildDataTypes;
    },
    getEligibleParentDataTypes() {
      return this.eligibleParentDataTypes;
    },
    attributes() {
      return this.datasetElements[this.datasetElementId].attributes;
    },
    hiddenAttributes() {
      return Object.entries(EDITOR_HIDDEN_FEATURES.attributes)
        .filter(([key]) => !isFeatureEnabled(this.featuresList, key))
        .flatMap(([, values]) => values);
    },
    allUsers() {
      return this.users || [];
    },
    listAttributes() {
      if (this.dataType && this.dataType.attributes) {
        return this.dataType.attributes.map((e) =>
          this.dataType.attributes.find(({ name }) => e.name === name)
        );
      }
      return [];
    },
    getMainPanelAttributes() {
      return this.dataTypes[this.dataType.id]?.editorConfig?.general.mainAttributesPanel;
    },
    getCentralPanelAttributes() {
      return this.dataTypes[this.dataType.id]?.editorConfig?.general.centralAttributesPanel;
    },
    hasSummary() {
      return this.dataTypes[this.dataType.id]?.editorConfig?.general.hasSummary;
    },
    getSummaryConfig() {
      return this.dataTypes[this.dataType.id]?.editorConfig?.general.summaryConfig;
    },
    friendlyId() {
      return this.attributes['friendly-id'] || '';
    },
    datasetElement() {
      return this.getDatasetElementById(this.datasetElementId);
    },
    eligibleRelationsDatatypes() {
      return this.dataTypes[this.dataType.id].editorConfig.general
        .eligibleRelationsDatatypes;
    },
    attachmentsDropdown() {
      return [
        {
          id: 'attachmentUrl',
          type: 'input',
          placeholder: this.$t('attachment.input.link.placeholder'),
          iconName: 'Link',
          inputSize: 'default',
          hasError: this.invalidAttachmentLink,
          value: this.attachmentUrlFieldValue
        },
        {
          id: 'attachmentName',
          type: 'input',
          placeholder: this.$t('attachment.input.name.placeholder'),
          inputSize: 'default',
          value: this.attachmentUrlName
        },
        {
          type: 'text',
          textStyle: `color: #d34343; display: ${this.invalidAttachmentLink ? 'inline' : 'none'};`,
          textContent: this.$t('attachment.error.label')
        },
        {
          type: 'button',
          label: this.$t('attachment.dropdown.button.link.label'),
          buttonType: 'filled',
          selected: false,
          showLeftIcon: false,
          color: 'primary',
          style: 'width: 100%;',
          disabled: this.attachmentUrlFieldValue.length === 0 || this.datasetElement.attributes?.attachments?.length >= MAX_ATTACHMENTS
        },
        {
          type: 'text',
          textContent: this.$t('attachment.dropdown.link.description')
        }
      ];
    }
  },
  watch: {
    datasetElementId() {
      if (!this.datasetElements[this.datasetElementId]) {
        this.close();
      }
    }
  },
  created() {
    const { debounce: debounceAttributes, cancelFn } = useDebounce(() => {
      this.updateAttrs(this.textAttributes);
      // this.textAttributes = {};
    }, 1000);
    this.debounceAttributes = debounceAttributes;
    this.cancelFn = cancelFn;
  },
  async mounted() {
    this.previousTitle = this.datasetElement.attributes?.title;

    await this.fetchEligibleDataTypes();
    await this.fetchHierarchicalRelations();
    await this.fetchDatasetElementDependencies();
    await this.fetchRelationTypes();
    await this.addFormulaResultToDatasetElement();
    await this.initializeAdditionalData();

    this.debouncedSearch = debounce((searchValue) => {
      let attributes = {};
      if (this.selectedType) {
        attributes = { type: this.selectedType };
      }
      this.fetchEligibleDatasetElements({
        relation: this.selectedRelation,
        attributes,
        dataTypeName: this.selectedDataTypeName,
        searchValue
      });
    }, 500);
  },
  methods: {
    ...mapActions('board', ['updateDatasetElementFromAPI', 'updateDatasetElementAttributes', 'deleteSelectedDatasetElement', 'updateDatasetElements',
      'updateAttributeInRelatedDatasetElements', 'fetchDatasetElementById', 'fetchFilterResults', 'synchronizeIndicatorRelations'
    ]),
    ...mapActions('hierarchy', [
      'createDependencyWithExistingDatasetElement',
      'createRelationByTypeWithExistingElement',
      'fetchEligibleDataTypes',
      'fetchHierarchicalRelations',
      'fetchDatasetElementDependencies',
      'fetchEligibleElementsForRelations',
      'resetRelationsData',
      'createDependencyWithNewDatasetElement',
      'createRelationFromNewElement',
      'deleteRelations',
      'unlinkRelationalElement'
    ]),
    ...mapActions('editor', ['closeEditor', 'openEditor', 'addPreviousDatasetElement',
      'openPreviousDetailsEditor']),
    ...mapMutations('editor', ['setPreviousDatasetElementIds', 'setFilterId']),
    ...mapMutations('board', ['setDialogBoxFlags', 'setBoardElementsInEdition', 'addDatasetElement', 'setDialogBoxInfo']),
    ...mapMutations('dock', ['setIsCreating']),
    ...mapActions('app', [
      'addFormulaResultToDatasetElement',
      'addFormulasResultsToElements',
      'updateAttributeInParent',
      'fetchRelationTypes'
    ]),
    closeDialogBox() {
      this.isDialogOpened = false;
    },
    getBoardgetElement() {
      if (this.filterId) {
        const filterDatasetElementId = Object.values(this.datasetElements).find((element) => element.attributes?.filter === this.filterId);
        const boardgetElement = Object.values(this.elements).find((element) => element.datasetElement.id === filterDatasetElementId.id);
        return boardgetElement;
      }
      return null;
    },
    async discardElement() {
      const isDeleted = await this.deleteSelectedDatasetElement(this.datasetElementId);
      const boardgetElement = this.getBoardgetElement();
      if (boardgetElement) {
        await this.fetchFilterResults({id: this.filterId, pageSize: boardgetElement?.attributes?.rowsPerPage});
      }
      this.setIsCreating(false);
      if (!isDeleted) {
        return;
      }
      this.closeDialogBox();
      this.resetEditorWrapperData();
    },
    async closeEditorWrapper() {
      const title = this.textAttributes?.title || this.datasetElement.attributes?.title;
      let attributes = {
        ...this.datasetElement.attributes,
        ...this.textAttributes
      };
      if (!title?.length) {
        if (this.isCreating) {
          this.isDialogOpened = true;
          return;
        } else {
          attributes = {
            ...this.datasetElement.attributes,
            title: this.previousTitle
          };
          this.updateAttributeInParent(this.datasetElementId, attributes);
          this.updateAttributeInRelatedDatasetElements(
            this.datasetElementId,
            attributes
          );
          this.cancelFn();
        }
      }
      try {
        if (this.filterId) {
          await this.updateDatasetElementFromAPI({datasetElementId: this.datasetElementId, attributes});
        } else {
          this.updateDatasetElementAttributes({
            datasetElementId: this.datasetElementId,
            attributes
          });
        }
      } catch (error) {
        console.error('Error updating dataset element:', error);
      } finally {
        this.resetEditorWrapperData();
        this.synchronizeIndicatorRelations({kpiId: this.datasetElementId});
        const boardgetElement = this.getBoardgetElement();
        await this.fetchFilterResults({
          id: this.filterId,
          pageSize: boardgetElement?.attributes?.rowsPerPage,
          page: this.filters[this.filterId]?.currentPage
        });

        if (this.filterId) {
          const isDatasetElementAdded = this.filters[this.filterId].elements.find(
            (element) => element.id === this.datasetElementId
          );
          if (!isDatasetElementAdded && this.isCreating) {
            this.selectedDataTypeName = this.getDataTypeName(this.dataType.id, this.datasetElements[this.datasetElementId]);
            this.setDialogBoxInfo({
              title: this.$t('boardget.add-element.dialog-box.title'),
              content: this.$t('boardget.add-element.dialog-box.content'),
              'submit-action': this.$t('boardget.add-element.dialog-box.submit-action'),
              'cancel-action': this.$t('boardget.add-element.dialog-box.cancel-action'),
              submitFunc: () => this.openElement(),
              cancelFunc: () => this.closeAddElementToBoardgetListDialogBox()
            });

            this.setDialogBoxFlags({
              ...this.dialogBoxFlags,
              isAddElementToBoardgetListDialogOpened: true
            });
          }
        }

        if (!this.dialogBoxFlags.isAddElementToBoardgetListDialogOpened && !this.isCreating) {
          this.setFilterId(null);
        }

        this.setPreviousDatasetElementIds([]);
        this.setIsCreating(false);
        this.close();
        await this.synchronizeIndicatorRelations({kpiId: this.datasetElementId});
      }
    },
    closeAddElementToBoardgetListDialogBox() {
      this.setDialogBoxFlags({
        ...this.dialogBoxFlags,
        isAddElementToBoardgetListDialogOpened: false
      });
      this.setDialogBoxInfo({});
    },
    openElement() {
      this.closeAddElementToBoardgetListDialogBox();
      this.openEditor({ datasetElementId: this.datasetElementId, boardElementId: this.elementId });
    },
    checkIfDatatypeHasTypeAttribute(dataTypeName) {
      const datatype = this.getDataTypeByName(dataTypeName);
      return (
        datatype && datatype.attributes.find((attribute) => attribute.name === 'type')
      );
    },
    async fetchEligibleDatasetElements({
      relation,
      attributes = {},
      dataTypeName,
      searchValue = ''
    }) {
      this.selectedType = null;
      this.selectedDataTypeName = dataTypeName;
      this.selectedRelation = relation;
      const typeAttribute = this.checkIfDatatypeHasTypeAttribute(dataTypeName);
      if (typeAttribute?.enum?.length) {
        this.selectedType = typeAttribute.enum[0].value;
      }

      if (!attributes.type && this.selectedType) {
        attributes.type = this.selectedType;
      }

      if (attributes.type) {
        this.selectedType = attributes.type;
      }

      const datatype = this.getDataTypeByName(dataTypeName);
      const searchQuery = searchValue ? `&search=${searchValue}` : '';
      const isDependency = relation === RELATION_TYPES[2];
      await this.fetchEligibleElementsForRelations(
        { type: relation,
          attributes,
          searchQuery,
          ...(isDependency && { dataTypeFilters: {[datatype.id]: {}} })
        }
      );
    },
    resetEditorWrapperData() {
      this.resetRelationsData();
      this.closeEditor();
    },
    getOwner(ownerId) {
      const owner = this.users.find((user) => user.id === ownerId);
      return owner ? owner : this.currentUser;
    },
    isObject(obj) {
      return typeof obj === 'object' && obj !== null && !Array.isArray(obj);
    },
    updateAttrs(attributes) {
      this.updateDatasetElementAttributes({
        datasetElementId: this.datasetElementId,
        attributes
      });
      this.updateAttributeInParent(
        this.datasetElementId,
        attributes
      );
      this.updateAttributeInRelatedDatasetElements(
        this.datasetElementId,
        attributes
      );
      this.addFormulaResultToDatasetElement();
    },
    onTextInput() {
      this.debounceAttributes(this.textAttributes);
    },
    handleTimeFrameAttributeChange({ value, previousAttrValue }) {
      const areEqual = compareProxies(previousAttrValue, value);
      if (areEqual) {
        return;
      }
      if (!value) {
        return {};
      }
      if (value.type === 'standard') {
        return value;
      }
      if (!value['end-date']) {
        return {};
      }
      const body = { ...value };
      if (!body['start-date']) {
        delete body['start-date'];
      }
      return body;
    },
    async handleAttributesChange({ attribute, value }) {
      const foundAttribute = this.listAttributes.find((attr) => attr.name === attribute);
      const { type } = foundAttribute;
      const previousAttrValue = this.datasetElement.attributes[attribute];
      const isEmptyIntAttrValue = type === 'int' && value.length === 0;
      const isTimestampedValue = type === 'timestamped_indicator';
      const isTimeFrameAttrValue = type === 'timeframe';

      if (!this.isObject(value) && (previousAttrValue === value || isEmptyIntAttrValue)) {
        return;
      }
      let updatedValue = value;
      if (type === 'level') {
        updatedValue = value.id;
      } else if (isTimestampedValue) {
        updatedValue = {
          value: Number(value),
          timestamp: String(new Date().toISOString()).slice(0, -5)
        };
      } else if (isTimeFrameAttrValue) {
        updatedValue = this.handleTimeFrameAttributeChange({ value, previousAttrValue });
        if (!updatedValue) {
          return;
        }
      }
      const attributes = {
        ...this.datasetElement.attributes,
        [attribute]: updatedValue
      };
      this.textAttributes = attributes;
      // this.textAttributes = {...this.textAttributes, [attribute]: updatedValue};
      if (foundAttribute.type === 'string' && !foundAttribute.enum) {
        this.onTextInput();
        return;
      }

      this.updateAttributeInParent(this.datasetElementId, attributes);
      this.updateAttributeInRelatedDatasetElements(this.datasetElementId, attributes);
      this.updateDatasetElementAttributes({
        datasetElementId: this.datasetElementId,
        attributes
        // attributes: {
        //   [attribute]: updatedValue
        // }
      });
      this.$emit('valueChanging');
      this.$emit('valueChange');
      await this.addFormulaResultToDatasetElement();
    },
    close() {
      if (
        this.currentSelectorInputValue.length ||
        this.currentMultipleSelectvalue.length
      ) {
        this.setDialogBoxFlags({
          ...this.dialogBoxFlags,
          isCancelElementRelationsEditing: true
        });
        return;
      }
      this.$emit('close');
    },
    escKeyAction(e) {
      if (e.key === 'Escape') {
        this.close();
      }
    },
    getDatasetElement(datasetElement) {
      this.currentSelectorInputValue = datasetElement ? datasetElement.inputText : '';
    },
    getChildrenToLink(childrenToLink) {
      this.currentMultipleSelectvalue = childrenToLink;
    },
    handleSearch(searchValue) {
      this.debouncedSearch(searchValue);
    },
    refreshDatasetElement(newDatasetElement) {
      if (newDatasetElement) {
        this.updateDatasetElements(newDatasetElement);
      }
    },
    async createRelationWithNewDatasetElement({ relation, body }) {
      const dataTypeId = this.getDataTypeByName(body.dataTypeName).id;
      delete body.dataTypeName;
      body = {
        attributes: {
          type: this.selectedType,
          title: body.title,
          owner: this.currentUser.id
        },
        dataTypeId
      };
      let newDatasetElement;
      if (relation === RELATION_TYPES[0]) {
        return;
      }
      if (relation === RELATION_TYPES[2]) {
        newDatasetElement = await this.createDependencyWithNewDatasetElement(body);
        this.addDatasetElement({datasetElementId: newDatasetElement.id, datasetElement: newDatasetElement});
        return;
      }
      newDatasetElement = await this.createRelationFromNewElement(body);
      this.addDatasetElement({datasetElementId: newDatasetElement.id, datasetElement: newDatasetElement});
    },
    async createRelationWithExistingDatasetElement({
      relation,
      eligibleDatasetElementId
    }) {
      if (relation === RELATION_TYPES[2]) {
        await this.createDependencyWithExistingDatasetElement({
          eligibleDatasetElementId
        });
        return;
      }
      await this.createRelationByTypeWithExistingElement({
        relation,
        eligibleDatasetElementId
      });
    },
    async executeAction({ action, relatedDatasetElementId, relationId, relationType }) {
      if (action === 'deleteAction') {
        await this.deleteRelations({ relationId, relatedDatasetElementId, relationType });
        this.addFormulaResultToDatasetElement();
        return;
      }
      if (action === 'unlinkAction') {
        await this.unlinkRelationalElement({ relationId, relatedDatasetElementId, relationType });
        this.addFormulaResultToDatasetElement();
      }
    },
    openNewElementDetails({id}) {
      this.addPreviousDatasetElement(this.datasetElementId);
      this.resetEditorWrapperData();
      setTimeout(() => this.openEditor({datasetElementId: id, boardElementId: this.elementId}));
    },
    goBackToPreviousElementDetails() {
      this.resetEditorWrapperData();
      setTimeout(() => this.openPreviousDetailsEditor());
    },
    updateAttachmentUrlInput({value, fieldId}) {
      if (fieldId === 'attachmentUrl') {
        this.attachmentUrlFieldValue = value.trim();
        this.invalidAttachmentLink = false;
      } else if (fieldId === 'attachmentName') {
        this.attachmentUrlName = value;
      }
    },
    async initializeAdditionalData() {
      const attachments = await attachmentActions.fetchAttachments(this.datasetElement.attributes?.attachments) ?? [];
      this.additionalData = { attachments };
    },
    async addAttachment() {
      this.invalidAttachmentLink = !isValidUrl(this.attachmentUrlFieldValue);
      if (this.invalidAttachmentLink) {
        return;
      } else {
        if (this.additionalData?.attachments?.length >= MAX_ATTACHMENTS) {
          return console.error('Max attachments limit reached');
        }
        const attachment = await attachmentActions.createUrlAttachment(
          this.attachmentUrlFieldValue,
          this.attachmentUrlName ? this.attachmentUrlName : this.attachmentUrlFieldValue);
        if (!attachment) {
          return;
        }
        const attachmentIds = [...(this.datasetElement.attributes.attachments ?? []), attachment.id];
        this.updateDatasetElementAttributes({
          datasetElementId: this.datasetElementId,
          attributes: {
            ...this.datasetElement.attributes,
            attachments: attachmentIds
          }
        });
        this.additionalData.attachments?.unshift(attachment);
      }
      this.attachmentUrlFieldValue = '';
      this.attachmentUrlName = '';
    },
    async handleAction({event, attachment}) {
      switch (event) {
      case 'openNewTab':
        this.handleOpenNewTab(attachment.url);
        break;
      case 'delete':
        this.openDeleteAttachmentDialog = true;
        this.attachmentToDelete = attachment;
        break;
      }
    },
    handleOpenNewTab(url) {
      if (url) {
        window.open(url, '_blank');
      } else {
        return console.error('No URL found');
      }
    },
    async handleDeleteAttachment() {
      await attachmentActions.deleteAttachment(this.attachmentToDelete.id);
      const attachmentIds = [...(this.datasetElement.attributes?.attachments || [])];
      const index = attachmentIds.indexOf(this.attachmentToDelete.id);
      if (index > -1) {
        attachmentIds.splice(index, 1);
      }
      this.updateDatasetElementAttributes({
        datasetElementId: this.datasetElementId,
        attributes: {
          ...this.datasetElement.attributes,
          attachments: attachmentIds
        }
      });
      await this.initializeAdditionalData();
      this.openDeleteAttachmentDialog = false;
    }
  }
};
</script>

<style scoped src="./BoardElementEditor.css" />
