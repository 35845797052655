<template>
  <div class="IobMultipleSelect">
    <div
      class="IobMultipleSelect-container"
      @click="toggleMenu"
    >
      <iob-badge
        v-if="singleSelect && singleSelectedItem && singleSelectedItem.text"
        style="margin: 4px"
        :label="singleSelectedItem.text"
        :size="props.size"
        :color="props.color"
        show-close-button
        :rounded="props.rounded"
        @close="$emit('onDeleteBadge', item)"
      />
      <div v-else-if="!singleSelect && displayedItems">
        <iob-badge
          v-for="(item, index) in displayedItems"
          :key="index"
          style="margin: 4px"
          :label="item.text"
          :size="props.size"
          :color="props.color"
          show-close-button
          :rounded="props.rounded"
          @close="$emit('onDeleteBadge', item)"
        />
        <IobHiddenList
          v-if="hiddenselectedItems && hiddenselectedItems.length "
          :hidden-items="hiddenselectedItems" 
        />
      </div>
      <input
        v-model="inputValue"
        class="IobMultipleSelect-input"
        @input="(event) => $emit('update:inputValue', event.target.value)"
      >
    </div>
    
    <outside-click-listener
      v-if="dropdownOpened "
      @outside-click="hideMenu"
    >
      <iob-dropdown
        v-if="filteredDataForDropdownMenu.length"
        class="IobMultipleSelect-dropdown"
        :items="filteredDataForDropdownMenu"
        :is-scrollable="isScrollable"
        :dropdown-style="dropdownStyle"
        @DropdownElementItem="onClickDropdownElement"
        @click.stop
      >
        <span
          v-if="
            filteredDataForDropdownMenu &&
              !filteredDataForDropdownMenu.length &&
              inputValue
          "
          class="IobMultipleSelect-alternativeText"
        >
          {{ alternativeText }}
        </span>
      </iob-dropdown>
      <slot />
    </outside-click-listener>
  </div>
</template>

<script setup>
import { computed, defineProps, ref, defineEmits } from 'vue';
import IobBadge from '../../Atoms/IobBadge/IobBadge.vue';
import IobDropdown from '../../Molecules/IobDropdown/IobDropdown.vue';
import OutsideClickListener from '../../OutsideClickListener/OutsideClickListener.vue';
import IobHiddenList from '../../Molecules/IobHiddenList/IobHiddenList.vue';

const props = defineProps({
  selectedItems: { type: [String, Array], default: () => [] },
  dropdownItems: { type: [String, Array], default: () => [] },
  singleSelect: { type: Boolean, default: false },
  singleSelectedItem: { type: Object, default: () => {} },
  size: {type:String, default:''},
  color: {type:String, default:''},
  rounded: {type:Boolean, default:false},
  isScrollable: { type: Boolean, default: false },
  dropdownStyle: {type:String, default: ''},
  maxDisplayedItems: { type: Number, default: 10 }
});

const emits = defineEmits(['onClickItem']);
const inputValue = ref('');
const dropdownOpened = ref(false);
const alternativeText = computed(
  () => `No result found for : ${inputValue.value}`
);

const hideMenu = () => {
  dropdownOpened.value = false;
};

const toggleMenu = (e) => {
  e.stopPropagation();
  dropdownOpened.value = true;
};

const filteredDataForDropdownMenu = computed(
  () =>
    props.dropdownItems &&
    props.dropdownItems.filter(
      (item) =>
        item.text &&
        item.text.toLowerCase().includes(inputValue.value.toLowerCase()) &&
        !props.selectedItems.some((selectedItem) => selectedItem.text.toLowerCase() === item.text.toLowerCase() && selectedItem.name === item.name)
    )
);
const hiddenselectedItems = computed(() => {
  return props.selectedItems.slice(props.maxDisplayedItems);
});
const displayedItems = computed(() => {
  return props.selectedItems.slice(0, props.maxDisplayedItems);
});
const onClickDropdownElement = ({ item }) => {
  dropdownOpened.value = false;
  emits('onClickItem', item);
  inputValue.value = '';
};
</script>
<style lang="scss" scoped src="./IobMultipleSelect.scss" />
