<template>
  <outside-click-listener
    :key="componentKey"
    class="CardCreationModal"
    @outside-click="closeCreationModal"
  >
    <div class="CardCreationModal-padding">
      <span class="CardCreationModal-label">
        Create new
      </span>
      <div
        v-if="dataTypeTypes && dataTypeTypes.length"
        class="CardCreationModal-types"
      >
        <iob-select-badge
          v-for="type in dataTypeTypes"
          :id="type.id"
          :key="type.id"
          :label="type.value"
          :shape-color="dataTypeColor"
          @click="createElementOnBoard({ type: type.value })"
        />
      </div>
      <div
        v-else
        class="CardCreationModal-types"
      >
        <iob-select-badge
          :label="dataTypeName"
          :shape-color="dataTypeColor"
          @click="createElementOnBoard({})"
        />
      </div>
    </div>
    <iob-separator
      v-if="dataTypeTypes && dataTypeTypes.length"
      color="secondary"
    />
    <iob-dropdown-search-select
      :dropdown-menu-items="datasetElementsWithTitle"
      :dropdown-style="datasetElementsDropdownStyle"
      :input-style="'margin: 16px 16px 0 16px;'"
      :sliced-data="true"
      :placeholder="placeholder"
      @onClickDropdownSearchSelectMenu="({ item }) => createElementOnBoard({ id: item.id })"
      @update:modelValue="searchDatasetElements"
    />
  </outside-click-listener>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { addTitleToDropdownData, formatDatasetElements, checkIfStringStartsWithVowal } from '../modals-utils';
import { getUserClickedToolEvent } from 'GLOBALS/events';
import { debounce } from 'SRC/utils/utils.js';

const store = useStore();

/** Data */
const datasetElementsDropdownStyle = ref('background-color: transparent; box-shadow: none; margin-left: 5px;');
const componentKey = ref(0);

/** Computed */
const viewTypes = computed(() => store.state.app.viewTypes);
const dataTypeId = computed(() => store.state.dock.dataTypeId);
const viewTypeId = computed(() => store.state.dock.viewTypeId);
const currentUser = computed(() => store.state.users.currentUser);
const dataType = computed(() => store.state.app.dataTypes[dataTypeId.value]);
const dataTypeName = computed(() => dataType.value.name.toLowerCase());
const dataTypeTypes = computed(() => {
  const dataTypeAttributes = dataType.value.attributes.find((attribute) => attribute.name === 'type');
  return dataTypeAttributes && dataTypeAttributes.enum;
});
const dataTypeColor = computed(() => {
  const dataTypeColorAttr = dataType.value.attributes.find((attribute) => attribute.name === 'color');
  return dataTypeColorAttr && dataTypeColorAttr.defaultValue;
});
const placeholder = computed(() => `Search ${checkIfStringStartsWithVowal(dataTypeName.value)} ${dataTypeName.value}`);
const datasetElements = computed(() => store.state.dock.datasetElements);
const formattedDatasetElements = computed(() => formatDatasetElements(datasetElements.value));
const datasetElementsWithTitle = computed(() =>
  addTitleToDropdownData(formattedDatasetElements.value, dataTypeName.value)
);

/** Methods */

const fetchDatasetElements = async () => {
  await store.dispatch('dock/fetchDatasetElements');
};

const createData = (id, type) => {
  const attributes = { owner: currentUser.value.id };
  if (id) {
    return { datasetElementId: id };
  }
  if (type) {
    return { attributes: {...attributes, type }};
  }
  return { attributes };
};

const createElementOnBoard = async ({ id, type }) => {
  const viewType = { ...viewTypes.value[viewTypeId.value], id: viewTypeId.value };
  const data = createData(id, type);
  document.dispatchEvent(getUserClickedToolEvent({viewType, ...data}));
  store.commit('dock/setCreationComponent', null);
  if (id) {
    store.commit('dock/setIsImporting', true);
    return;
  }
  store.commit('dock/setIsImporting', false);
  store.commit('dock/setIsCreating', true);
};

const searchDatasetElements = debounce(async (value) => {
  await store.dispatch('dock/searchDatasetElements', {query: value});
}, 500);

const closeCreationModal = (e) => {
  const cards = document.querySelector('#dock-category-cards');
  if (cards.contains(e.target)) {
    componentKey.value++;
    return;
  }
  store.dispatch('board/closeComponent', 'CardCreationModal');
};

/** Watchers */

watch(() => dataTypeId.value, async (newId) => {
  if (newId) {
    await fetchDatasetElements();
  }
}, { immediate: true, deep: true });

</script>

<style lang="scss" src="./CardCreationModal.scss" scoped />
