import { computed, ref, unref } from 'vue';

/**
 * @typedef { import("vue").Ref } Ref
 */

/**
 * @typedef {Object} PaginationConfig
 * @property {number} currentPage The current page of the pagination
 * @property {number} rowsPerPage The number of rows per page
*/

/**
 * Vue Composable that paginates an array using a preset or custom configuration
 * @param {Ref} arrayToPaginate The array to paginate
 * @param {PaginationConfig} config The configuration object for the array to paginate
 * @returns
 */
export function usePagination(arrayToPaginate, config = {}) {
  const rowsPerPage = ref(config.rowsPerPage || 100);
  const currentPage = ref(config.currentPage || 1);

  const paginatedArray = computed(() => {
    const array = unref(arrayToPaginate).slice((currentPage.value - 1) * rowsPerPage.value, currentPage.value * rowsPerPage.value);
    return array;
  });

  const numberOfPages = computed(() =>
    Math.ceil((unref(arrayToPaginate).length || 0) / rowsPerPage.value)
  );

  const totalCount = computed(() => unref(arrayToPaginate).length);

  /**
     * Function to go to the next page
    */
  const next = () => {
    if (currentPage.value < numberOfPages.value) {
      currentPage.value++;
    }
  };

  /**
     * Function to go to the previous page
    */
  const previous = () => {
    if (currentPage.value > 1) {
      currentPage.value--;
    }
  };

  /**
     * Function to go to the last page
    */
  const last = () => {
    currentPage.value = numberOfPages.value;
  };

  /**
     * Function to go to the first page
    */
  const first = () => {
    currentPage.value = 1;
  };

  /**
     * Function to go to a specific page
     */
  const goToPage = (page) => {
    if (page >= 1 && page <= numberOfPages.value) {
      currentPage.value = page;
    }
  };

  return {
    currentPage,
    paginatedArray,
    numberOfPages,
    rowsPerPage,
    totalCount,
    next,
    previous,
    last,
    first,
    goToPage
  };
}
