import { get, post } from 'SRC/api/index.js';
import { DATASET_ELEMENTS_PAGE_SIZE } from 'GLOBALS/constants.js';

const actions = {
  async fetchDockConfig({ rootState, commit }) {
    const dockConfig = await get('/dock/config');
    const { viewTypes } = rootState.app;
    if (dockConfig) {
      commit('setCategories', dockConfig.categories);
      const toolsets = Object.entries(dockConfig.toolsets).reduce(
        (acc, [key, value]) => {
          const data = {};
          if (value.viewTypeId) {
            data.viewType = viewTypes[value.viewTypeId];
          }
          if (value.expandableList) {
            data.expandableList = value.expandableList.map((element) => ({
              ...element,
              viewType: viewTypes[element.viewTypeId]
            }));
          }
          acc[key] = {
            ...value,
            ...data
          };
          return acc;
        },
        {}
      );
      commit('setToolsets', toolsets);
    }
  },
  async fetchDatasetElements({ commit, state }) {
    const query = [`dataTypeIds=${state.dataTypeId}`];
    const response = await get(`/dataset-elements?${query}&page=1&size=${DATASET_ELEMENTS_PAGE_SIZE}`);
    if (!response) {
      return { error: 'Error occured in fetchDatasetElements' };
    }
    const datasetElements = {};
    response.data.forEach((datasetElement) => {
      datasetElements[datasetElement.id] = datasetElement;
    });
    commit('setDatasetElements', datasetElements);
  },
  async searchDatasetElements({ commit, state }, {query, withTypeId = true}) {
    try {
      const body = {
        attributes: {},
        ...(withTypeId && { dataTypeFilters: {[state.dataTypeId]: {}} })
      };
      const response = await post(`/dataset-elements/search-requests?search=${query}&page=1&size=${DATASET_ELEMENTS_PAGE_SIZE}`, body);

      const datasetElements = response.data.reduce((acc, datasetElement) => {
        acc[datasetElement.id] = datasetElement;
        return acc;
      }, {});

      commit('setDatasetElements', datasetElements);
    } catch (error) {
      console.error('Error occured in searchDatasetElements', error);
      return { error: 'Error occured in searchDatasetElements' };
    }
  }
};

export default actions;
