<template>
  <div
    class="pbi-viewer-wrapper level-2"
    data-target="board-element"
  >
    <div
      v-if="!accessToken"
      class="pbi-connect-container"
    >
      <div class="pbi-connect-wrapper">
        <img
          class="not-connected-image"
          :src="base64Pic"
          alt="PowerBI bretzel electric cord"
        >
        <div class="pbi-connect-bottom">
          <span class="not-logged-in">
            {{ $t('embed.powerbi.message.auth') }}
          </span>
          <power-b-i-o-auth-button />
        </div>
      </div>
    </div>
    <div
      v-else
      class="pbi-viewer"
    >
      <power-b-i-dashboard-embed
        v-if="configObject?.type == 'dashboard'"
        :key="configObject.id"
        class="pbi-viewer"
        :embed-config="configObject"
        :inert="isResizing"
      />

      <power-b-i-report-embed
        v-if="configObject?.type == 'report'"
        :key="configObject.id"
        class="pbi-viewer"
        :embed-config="configObject"
        :inert="isResizing"
      />

      <power-b-i-visual-embed
        v-if="configObject?.type == 'visual'"
        :key="configObject.id"
        class="pbi-viewer"
        :embed-config="configObject"
        :inert="isResizing"
      />
      <div
        v-if="configObject == null || error"
        class="error-container"
      >
        <div class="error-container">
          <div class="error-message-wrapper">
            <IconLoader
              name="AlertCircle"
              color="#A43434"
            />
            <span class="error-message">{{ errorMsg }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PowerBIDashboardEmbed, PowerBIReportEmbed, PowerBIVisualEmbed } from 'powerbi-client-vue-js';
import { mapState } from 'vuex';
import embedConfig from './utils/embed-config';
import parse from './utils/parser';
import PowerBIOAuthButton from './PowerBIOAuthButton.vue';
import store from 'SRC/store';
import pbiBretzelCord from 'SRC/assets/images/PBI_Bretzel_Cord.png';
import { useI18n } from 'vue-i18n';
import { unsanitizeUrl } from 'BOARD/components/Embed/utils/embed-parser';

export default {
  name: 'PowerBI',
  components: {
    PowerBIDashboardEmbed,
    PowerBIReportEmbed,
    PowerBIVisualEmbed,
    PowerBIOAuthButton
  },
  props: {
    url: {
      type: String,
      required: true
    }
  },
  data: () => ({
    t: useI18n().t,
    errorMsg: '',
    error: false,
    base64Pic: pbiBretzelCord,
    configObject: {}
  }),
  computed: {
    ...mapState('powerbi', ['accessToken', 'settings'])
  },
  watch: {
    settings() {
      if (this.settings?.id && !this.accessToken) {
        const infos = { settingsId: this.settings.id };
        store.dispatch('powerbi/retrieveAccessToken', { code: null, infos });
      }
    },
    url: {
      handler(newUrl) {
        if (!newUrl) {
          this.error = true;
        }
        this.handlePowerBiConfig();
      },
      immediate: true
    },
    accessToken() {
      this.handlePowerBiConfig();
    }
  },
  created() {
    this.errorMsg = this.t('embed.powerbi.message.error');
    if (!this.settings) {
      store.dispatch('powerbi/retrieveSettings');
    }
  },
  methods: {
    async parseUrl() {
      try {
        const parsedUrl = await parse(unsanitizeUrl(this.url), this.accessToken);
        return parsedUrl;
      } catch (error) {
        this.error = true;
        throw error;
      }
    },

    async generateEmbedConfig() {
      //to reset config object to force rerender as a workaround for PowerBi lib issue
      this.configObject = {};
      try {
        const parsedUrl = await this.parseUrl(this.url, this.accessToken);
        this.configObject = embedConfig(parsedUrl, this.accessToken);
        this.error = false;
      } catch (error) {
        this.error = true;
        this.configObject = {};
      }
    },
    async handlePowerBiConfig() {
      if (!this.accessToken || !this.url) {
        this.configObject = {};
      } else {
        this.generateEmbedConfig();
      }
    }
  }
};
</script>

<style scoped src='./PowerBI.css' />
