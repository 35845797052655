<template>
  <div class="IobSelect-container">
    <button
      ref="hideDropdownButton"
      :class="containerClasses"
      :disabled="disabled"
      @click="onSelectClick"
    >
      <div class="IobSelect-left">
        <div class="IobSelect-icon">
          <icon-loader
            v-if="modelValue.iconName"
            :name="modelValue.iconName"
            :size="iconSize"
            class="icon"
          />
        </div>
        <iob-avatar
          v-if="modelValue.avatarPicture || modelValue.altText"
          :picture="modelValue.avatarPicture"
          :alt-text="modelValue.altText"
          :color="modelValue.initialsColor"
          :size="elementSize"
        />
        <iob-badge
          v-if="modelValue.badgeIcon"
          :color="modelValue.badgeColor"
          :icon="modelValue.badgeIcon"
          :size="elementSize"
        />
        <icon-loader
          v-if="modelValue.iconColorName"
          :name="modelValue.iconColorName"
          :color="modelValue.iconColor"
          has-background-color
        />
        <iob-shape-color
          v-if="modelValue.shapeType"
          :type="modelValue.shapeType"
          :color="modelValue.shapeColor"
        />
        <span :class="textClasses">{{ modelValue.label ? modelValue.label : label }}</span>
      </div>
      <icon-loader
        class="arrow icon"
        :name="arrowIcon"
      />
      <iob-dropdown
        v-if="isClicked"
        :items="items"
        :style="dropdownStyle"
        @DropdownElementItem="handleSelectedElement"
      />
    </button>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, onMounted, onBeforeMount, onUpdated } from 'vue';
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobAvatar from '../../Molecules/IobAvatar/IobAvatar.vue';
import IobDropdown from '../../Molecules/IobDropdown/IobDropdown.vue';
import IobBadge from '../../Atoms/IobBadge/IobBadge.vue';
import IobShapeColor from '../../Atoms/IobShapeColor/IobShapeColor.vue';

const props = defineProps({
  modelValue: { type: Object, default: () => ({}) },
  color: { type: String, default: 'secondary' },
  type: { type: String, default: 'filled' },
  size: { type: String, default: 'medium' },
  disabled: { type: Boolean, default: false },
  altText: { type: String, default: '' },
  initialsColor: { type: String, default: '' },
  iconName: { type: String, default: '' },
  avatarPicture: { type: String, default: '' },
  label: { type: String, default: '' },
  badgeColor: { type: String, default: '' },
  badgeIcon: { type: String, default: '' },
  iconColorName: { type: String, default: '' },
  iconColor: { type: String, default: '' },
  shapeType: { type: String, default: '' },
  shapeColor: { type: String, default: '' },
  items: { type: Array, required: true },
});

const emit = defineEmits(['update:modelValue']);

const isClicked = ref(false);
const hideDropdownButton = ref(null);
const iconSize = ref('default');
const elementSize = ref('small');

const secondaryColorMap = {
  filled: 'IobSelect-secondary-filled',
  outlined: 'IobSelect-secondary-outlined',
  ghost: 'IobSelect-secondary-ghost',
};

const secondarySoftColorMap = {
  filled: 'IobSelect-secondary-soft-filled',
  outlined: 'IobSelect-secondary-soft-outlined',
  ghost: 'IobSelect-secondary-soft-ghost',
};

const sizeMap = {
  default: 'IobSelect-default',
  medium: 'IobSelect-medium',
  large: 'IobSelect-large',
};

const textClassMap = {
  false: 'text',
  true: 'disabled-text',
};

const textPaddingClassMap = {
  default: 'text-default',
  medium: 'text-medium',
  large: 'text-large',
};

const containerClasses = computed(() => {
  if (props.color === 'secondary') {
    return `IobSelect ${secondaryColorMap[props.type]}${
      props.disabled ? '-disabled' : ''
    } ${sizeMap[props.size]} focus-outside`;
  } else {
    return `IobSelect ${secondarySoftColorMap[props.type]}${
      props.disabled ? '-disabled' : ''
    } ${sizeMap[props.size]} focus-outside`;
  }
});

const textClasses = computed(() => {
  if (props.color === 'secondary') {
    return `${secondaryColorMap[props.type]}-${textClassMap[props.disabled]} ${
      secondaryColorMap[props.type]
    }-${textPaddingClassMap[props.size]}`;
  } else {
    return `${secondarySoftColorMap[props.type]}-${
      textClassMap[props.disabled]
    } ${secondarySoftColorMap[props.type]}-${textPaddingClassMap[props.size]}`;
  }
});
const arrowIcon = computed(() =>
  isClicked.value ? 'ChevronUp' : 'ChevronDown'
);
const dropdownStyle = {
  position: 'absolute',
  top: '100%',
  left: '0',
  width: '100%',
  zIndex: '100',
};
const onSelectClick = () => {
  isClicked.value = !isClicked.value;
};

const closeDropdown = (element) => {
  if (
    hideDropdownButton.value &&
    !hideDropdownButton.value.contains(element.target)
  ) {
    isClicked.value = false;
  }
};

const handleSelectedElement = ({ item }) => {
  isClicked.value = false;
  emit('update:modelValue', item);
};

const setElementSize = () => {
  if (props.size === 'default') {
    iconSize.value = props.size;
    elementSize.value = 'small';
  } else if (props.size === 'large') {
    iconSize.value = props.size;
    elementSize.value = 'default';
  } else {
    iconSize.value = 'default';
    elementSize.value = 'xsmall';
  }
};

onBeforeMount(() => {
  window.removeEventListener('click', closeDropdown);
});
onMounted(() => {
  window.addEventListener('click', closeDropdown);
  setElementSize();
});

onUpdated(() => {
  setElementSize();
});
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-select.scss" />
<style lang="scss" scoped src="./IobSelect.scss" />
